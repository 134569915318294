<template>
  <div>
    <v-row
      class="page-break"
      no-gutters>
      <v-col cols="12">
        <p class="field-label">
          <b>Brand:</b>
          <span>
            {{ brand || '' }}
          </span>
        </p>
      </v-col>
      <v-col cols="2">
        <p class="field-label">
          <b> ID: </b>
          <span>{{ sketchId }} </span>
        </p>
      </v-col>
      <v-col cols="2">
        <p class="field-label">
          <b> Date: </b>
          <span>{{ createdAt }} </span>
        </p>
      </v-col>
      <v-col cols="4">
        <p class="field-label">
          <b> Product Type: </b>
          <span>{{ category }} </span>
        </p>
      </v-col>
      <v-col cols="4">
        <p class="field-label">
          <b> Collection: </b>
          <span>{{ collectionName }} </span>
        </p>
      </v-col>
      <v-col cols="2">
        <p class="field-label">
          <b> Designer: </b>
          <span>{{ designer }} </span>
        </p>
      </v-col>
      <v-col cols="2">
        <p class="field-label">
          <b> Merchandizer: </b>
          <span>{{ merchandiser }} </span>
        </p>
      </v-col>
      <v-col cols="4">
        <p class="field-label">
          <b> Factory: </b>
          <span>{{ factory }} </span>
        </p>
      </v-col>
      <v-col
        cols="12"
        class="pb-3">
        <v-divider />
      </v-col>
    </v-row>
    <v-row class="sketch-image-row">
      <v-col cols="12">
        <p class="field-label mb-0 text-center">
          <b>
            Sketch Image:
          </b>
        </p>
      </v-col>
      <v-col
        v-for="(skImage, skImgIndex) in sketchImages"
        :key="`sketch-images(${skImgIndex})`"
        :class="{ 'sketch-image-singel': isSingleSketchImage }"
        class="sketch-image img-preview-col"
        cols="12">
        <div class="img-preview-wrap">
          <img
            class="image-preview export-preview-img"
            :src="skImage">
        </div>
      </v-col>
      <v-col cols="12">
        <p>
          <b>Note:</b>
        </p>
        <p>
          {{ sketchNote }}
        </p>
      </v-col>
    </v-row>
    <div class="page-break-after"></div>
    <v-row class="material-row">
      <v-col cols="12">
        <p class="field-label mb-0 text-center">
          <b>
            Material Detail:
          </b>
        </p>
      </v-col>
    </v-row>
    <template v-for="(material, materialIndex) in materials">
      <v-row
        :key="`material-row(${materialIndex})`"
        :class="{'page-break ': materialIndex !== 0}"
        class="material-image-row">
        <v-col
          v-for="(materialImage, materialImageIndex) in material.images"
          :key="`material-images(${materialImageIndex})`"
          class="material-image img-preview-col"
          :class="{
            'last-material-image': isLastMaterialImage(materialIndex, materialImageIndex)
          }"
          cols="6">
          <div class="img-preview-wrap">
            <img
              class="image-preview export-preview-img"
              :src="materialImage">
          </div>
        </v-col>
        <v-col cols="9">
          <p>
            <b>Note:</b>
          </p>
          <p>
            {{ material.note || '' }}
          </p>
        </v-col>
        <v-col cols="3">
          <p>
            <b>Quantity:</b>
          </p>
          <p>
            {{ material.quantity || '' }}
          </p>
        </v-col>
      </v-row>
      <div
        :key="`page-break-after-${materialIndex}`"
        class="page-break-after" />
    </template>
    <v-row>
      <v-col cols="12">
        <p><b>Size Details</b></p>
        <v-row>
          <v-col cols="auto">
            <v-simple-table
              v-if="hasSizeDetail"
              dense>
              <thead>
                <tr>
                  <th>
                    <p class="label mb-0 text-center">
                      Detail / Size
                    </p>
                  </th>
                  <th
                    v-for="(size) in sizeDetail"
                    :key="`head-sizeDetail-${size.size}`">
                    <p class="label mb-0 text-uppercase text-center">
                      {{ size.size }}
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(key, keyIndex) in optionKeys"
                  :key="`mainkey-${keyIndex}`">
                  <td class="text-center">
                    {{ key }}
                  </td>
                  <td
                    v-for="(size, sizeIndex) in sizeDetail"
                    :key="`sizeDetail-${sizeIndex}-${keyIndex}`"
                    class="text-center">
                    {{ size.options[keyIndex].value }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <p
              v-else
              class="text-center">
              ไม่มี Size Details
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div class="page-break-after"></div>
  </div>
</template>

<script>
import { prototypeData } from '@/assets/js/DefaultPrototypeData'
import { mapGetters } from 'vuex'

export default {
  props: {
    prototype: {
      type: Object,
      default: () => prototypeData
    }
  },
  computed: {
    ...mapGetters({
      storeSetting: 'Store/storeSetting'
    }),
    brand () {
      return this.prototype?.sketch?.brand || ''
    },
    sketchId () {
      return this.prototype?.sketch?.sketchId || ''
    },
    createdAt () {
      return this.prototype?.sketch?.createdAt ? this.$dayjs(this.prototype.sketch.createdAt).format('DD/MM/YYYY') : ''
    },
    category () {
      return this.prototype?.sketch?.category?.name || ''
    },
    collectionName () {
      return this.prototype?.sketch?.collectionName || ''
    },
    factory () {
      return this.prototype?.sketch?.factory?.name || ''
    },
    merchandiser () {
      return this.prototype?.sketch?.merchandiser?.name || ''
    },
    designer () {
      return this.prototype?.sketch?.designer?.name || ''
    },
    sketchImages () {
      return this.prototype?.sketch?.images || []
    },
    isSingleSketchImage () {
      return this.sketchImages.length === 1
    },
    sketchNote () {
      return this.prototype?.sketch?.note || ''
    },
    materials () {
      return this.prototype?.sketch?.materials || []
    },
    modMaterial () {
      const arrMaterial = []
      for (let i = 0; i < this.materials.length; i++) {
        const qtyMaterial = this.materials[i]?.images?.length || 0
        arrMaterial.push((qtyMaterial - (qtyMaterial % 4)) - 1)
      }
      return arrMaterial
    },
    sizeDetail () {
      return this.prototype?.sketch?.sizeDetail || []
    },
    optionKeys () {
      const { sizeDetail } = this
      if (!Array.isArray(sizeDetail) || sizeDetail.length === 0 || !Array.isArray(sizeDetail[0].options) || sizeDetail[0].options.length === 0) {
        return []
      }
      return sizeDetail[0].options.map((option) => option.key)
    },
    hasSizeDetail () {
      return this.optionKeys.length > 0
    }
  },
  methods: {
    isLastMaterialImage (mIndex, imgIndex) {
      return imgIndex > this.modMaterial[mIndex]
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 8.0pt;
  font-size: 11pt;
  line-height: 107%;
}
.img-preview-col {
  .img-preview-wrap {
    width: 100%;
    height: 100%;
    position: relative;
    img {
      margin: 0 auto;
      max-height: 100%;
      padding: 12px;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
.sketch-image-row {
  .img-preview-col {
    height: 100vh;
  }
  .img-preview-col:first-child {
    height: 80vh;
  }
  .img-preview-col:last-child {
    height: 90vh;
  }
  .img-preview-col.sketch-image-singel {
    height: 60vh;
  }
}
.material-image-row {
  .material-image {
    height: 40vh;
  }
}
</style>
