<template>
  <v-main>
    <div
      size="A4"
      layout="landscape"
      class="page-a4 container elevation-5">
      <div
        v-for="prototype in prototypes"
        :key="prototype.id">
        <sketch-for-export
          v-if="isNewData(prototype)"
          :prototype="prototype" />
        <sketch-for-export-old
          v-else
          :prototype="prototype" />
      </div>
    </div>
  </v-main>
</template>

<script>
import { mapGetters } from 'vuex'
import SketchForExport from '../components/SketchForExport.vue'
import SketchForExportOld from '../components/SketchForExportOld.vue'

export default {
  components: {
    SketchForExport,
    SketchForExportOld
  },
  computed: {
    ...mapGetters({
      prototypes: 'Prototypes/prototypes'
    })
  },
  mounted () {
    window.print()
  },
  methods: {
    isNewData (prototype) {
      return 'fabrics' in prototype.sketch
    }
  }
}
</script>

<style lang="scss">
div[size="A4"][layout="landscape"] {
  display: block;
  width: 297mm;
  min-height: 210mm;
  margin: 10px auto;
  padding: calc(12.7mm - 24px);
  background-color: #ffffff;
  word-wrap: break-word;
  page-break-after: always;
  .col {
    position: relative;
  }
  .buttun-remove-product {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 1;
  }
}
// #app div[size=A4] .col {
//   display: inline-block;
//   flex-basis: unset;
//   -webkit-box-flex: unset;
//   flex-grow: unset;
// }
// #app div[size=A4] .row {
//   display: block;
//   width: 100%;
// }
#app div[size=A4] .row + .row {
  margin-top: 0;
}
.page-break-after {
  display: none;
}
@media print {
  .page-break {
    page-break-before: always;
    page-break-inside: avoid;
  }
  .page-break-after {
    display: block;
    page-break-after: always;
  }
}
</style>
